import React, {useState, useEffect, useRef} from 'react';

interface SmoothValueAnimationProps {
    value: number | null | undefined;
    decimals?: number;
    duration?: number;
    placeholder?: React.ReactNode;
}

const SmoothValueAnimation = ({
                                  value,
                                  decimals = 1,
                                  duration = 500,
                                  placeholder = '-'
                              }: SmoothValueAnimationProps): JSX.Element => {
    const [displayValue, setDisplayValue] = useState<number | null | undefined>(value);
    const animationFrameRef = useRef<number | null>(null);
    const startTimeRef = useRef<number | null>(null);
    const startValueRef = useRef<number | null>(null);

    useEffect(() => {
        if (value === null || value === undefined) {
            setDisplayValue(null);
            return;
        }

        if (displayValue === null || displayValue === undefined) {
            setDisplayValue(value);
            return;
        }

        startValueRef.current = displayValue;
        startTimeRef.current = null;

        const animate = (timestamp: number) => {
            if (!startTimeRef.current) startTimeRef.current = timestamp;
            const progress = timestamp - startTimeRef.current;

            if (progress < duration) {
                const ratio = progress / duration;
                const currentValue = startValueRef.current! + (value - startValueRef.current!) * ratio;

                setDisplayValue(currentValue);

                animationFrameRef.current = requestAnimationFrame(animate);
            } else {
                setDisplayValue(value);
            }
        };

        animationFrameRef.current = requestAnimationFrame(animate);

        return () => {
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [value, duration]);

    if (displayValue === null || displayValue === undefined) {
        return <>{placeholder}</>;
    }

    return <>{displayValue.toFixed(decimals)}</>;
};

export default SmoothValueAnimation;