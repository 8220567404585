import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useRef,
} from "react";
import Modal from "@mui/material/Modal";
import "./raceSelectModal.scss";
import {
    ContentProxyRaceDay,
    ContentProxyRaceDayStatus,
    ContentProxyRaceEvent,
    TicketAvailableEnum,
} from "../../../../../models/contentProxyModel";
import {formatRaceTimeFriendlyText} from "../../../../../utils/helpers";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {CurrentRaceEventContext} from "../../../../../context/currentRaceEvent.context";
import {LoginContext} from "../../../../../context/login.context";

interface RaceSelectModalProps {
    isRaceSelectorModalOpen: boolean;
    setRaceSelectorModalOpen: Dispatch<SetStateAction<boolean>>;
    event: ContentProxyRaceEvent;
    changeRaceEvent: (
        eventId: string,
        raceDay: ContentProxyRaceDay,
        isLiveRace: boolean
    ) => void;
}

const RaceSelectModal = ({
                             setRaceSelectorModalOpen,
                             isRaceSelectorModalOpen,
                             event,
                             changeRaceEvent,
                         }: RaceSelectModalProps) => {

    const {broadcasterRegionalConfig} = useContext(CurrentRaceEventContext)
    const {isInternalUser} = useContext(LoginContext)
    const getOption = (raceDayOption?: ContentProxyRaceDay, index?: number) => {
        const setPlayedValue = () => {
            raceDayOption &&
            changeRaceEvent(
                event.contentfulId,
                raceDayOption,
                raceDayOption.state === ContentProxyRaceDayStatus.InProgress
            );
        };


        const afterReplayDelayHours = () => {
           if(raceDayOption) {
               //handle broadcast delay restrictions by region
               const millisecondsInHour = 3600000;
               const currentDateToCompare = new Date();
               let currentEventEndDateAndTime = new Date(raceDayOption.endDateTime);
               if ((broadcasterRegionalConfig?.replayDelayHours &&
                       (currentDateToCompare.getTime() > (currentEventEndDateAndTime.getTime() + (broadcasterRegionalConfig?.replayDelayHours * millisecondsInHour))))
                   || (currentDateToCompare.getTime() > currentEventEndDateAndTime.getTime() && !broadcasterRegionalConfig?.replayDelayHours)
                   || (isInternalUser && raceDayOption.state === ContentProxyRaceDayStatus.ReplayAvailable)) {
                   return true
               } else {
                   return false
               }
           }

        }


        return (
            <div className="modalRacesContainer--option" key={index}>
                <img
                    className="modalRacesContainer--option__img"
                    src={event.venueImage?.file.url}
                    alt=""
                />

                <div className="modalRacesContainer--option__info">
                    <h2 className="modalRacesContainer--option__info-title">
                        {raceDayOption
                            ? raceDayOption.shortName.toUpperCase()
                            : event.shortName.toUpperCase()}
                    </h2>
                    <p className="modalRacesContainer--option__info-description">
                        {raceDayOption ? raceDayOption.name : event.name}
                    </p>
                    {!raceDayOption &&
                        event.ticketAvailableState === TicketAvailableEnum.no && (
                            <p className="modalRacesContainer--option__info-description">
                                {event.insightsTicketsUnavailableText
                                    ? event.insightsTicketsUnavailableText
                                    : "Check back soon to get tickets"}
                            </p>
                        )}
                </div>
                <div className="modalRacesContainer--option__button-container">
                    {raceDayOption &&
                        (raceDayOption.state === ContentProxyRaceDayStatus.NotStarted &&
                        event.ticketAvailableState !== TicketAvailableEnum.no ? (
                            <Link
                                className="modalRacesContainer--option__tickets"
                                to={{pathname: event.ticketsUrl}}
                                target="_blank"
                                style={{
                                    pointerEvents:
                                        event.ticketAvailableState !== TicketAvailableEnum.soldOut
                                            ? undefined
                                            : "none",
                                }}
                            >
                                <Button
                                    className={`modalRacesContainer--option__button ${
                                        event.ticketAvailableState === TicketAvailableEnum.yes
                                            ? ""
                                            : "modalRacesContainer--option__button--sold-out"
                                    }`}
                                >
                                    {event.ticketAvailableState === TicketAvailableEnum.yes
                                        ? "GET TICKETS"
                                        : "SOLD OUT"}
                                </Button>
                            </Link>
                        ) : raceDayOption &&
                        (raceDayOption.state === ContentProxyRaceDayStatus.InProgress ||
                            (raceDayOption.state ===
                            ContentProxyRaceDayStatus.ReplayAvailable && afterReplayDelayHours())) ? (
                            <Button
                                className="modalRacesContainer--option__button"
                                variant="contained"
                                onClick={setPlayedValue}
                            >
                                {raceDayOption &&
                                    (raceDayOption.state === ContentProxyRaceDayStatus.InProgress
                                        ? "Watch Live"
                                        : "Play")}
                            </Button>
                        ) : (
                            raceDayOption &&
                            (raceDayOption.state === ContentProxyRaceDayStatus.Finished || (raceDayOption.state ===
                                ContentProxyRaceDayStatus.ReplayAvailable && !afterReplayDelayHours())) && (
                                <Button
                                    className="modalRacesContainer--option__label"
                                    variant="contained"
                                >
                                    Replay Soon
                                </Button>
                            )
                        ))}

                    {!raceDayOption &&
                        event.ticketAvailableState !== TicketAvailableEnum.no && (
                            <Link
                                className="modalRacesContainer--option__tickets"
                                to={{pathname: event.ticketsUrl}}
                                target="_blank"
                                style={{
                                    pointerEvents:
                                        event.ticketAvailableState !== TicketAvailableEnum.soldOut
                                            ? undefined
                                            : "none",
                                }}
                            >
                                <Button
                                    className={`modalRacesContainer--option__button ${
                                        event.ticketAvailableState === TicketAvailableEnum.yes
                                            ? ""
                                            : "modalRacesContainer--option__button--sold-out"
                                    }`}
                                >
                                    {event.ticketAvailableState === TicketAvailableEnum.yes
                                        ? "GET TICKETS"
                                        : "SOLD OUT"}
                                </Button>
                            </Link>
                        )}
                </div>
            </div>
        );
    };

    return (
        <Modal
            open={isRaceSelectorModalOpen}
            onClose={() => setRaceSelectorModalOpen(false)}
            style={{backgroundColor: "rgba(0, 0, 0, 0.6)"}}
        >
            <div className="raceSelectModal">
                <button
                    onClick={() => setRaceSelectorModalOpen(false)}
                    className="closeIcon"
                    type="button"
                >
                    <CloseIcon fontSize={"medium"} color={"action"}/>
                </button>

                <div className="modalInfoContainer">
                    <div className="modalInfoBackground">
                        <div className="modalInfoBackgroundShadow"/>
                        <div
                            className="modalInfoBackgroundImage"
                            style={{
                                backgroundImage: `url(${
                                    event.eventBannerLogo
                                        ? event.eventBannerLogo?.file.url
                                        : event.venueImage?.file.url
                                })`,
                            }}
                        />
                    </div>
                    <div className="modalInfo">
                        <div className="modalInfo--title-container">
                            <h1 className="modalInfo--title">{event.shortName}</h1>
                        </div>

                        <p className="modalInfo--location">{event.locationName}</p>

                        <p className="modalInfo--date">
                            {formatRaceTimeFriendlyText(
                                event.startDateTime,
                                event.endDateTime
                            )}
                        </p>
                        <p className="modalInfo--description">
                            {event.preRaceSummary} {localStorage.getItem("pointsAwarded")}
                        </p>
                    </div>
                </div>
                <div className="modalRacesContainer">
                    {event?.raceDays
                        ? event?.raceDays?.map((raceDay: ContentProxyRaceDay, index) =>
                            getOption(raceDay, index)
                        )
                        : getOption()}
                </div>
            </div>
        </Modal>
    );
};

export default RaceSelectModal;