import React, {ChangeEvent, MouseEvent, useContext, useEffect, useRef, useState} from 'react';
import './VideoReplayComponent.scss';
import {DateTime} from 'luxon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PauseIcon from '@mui/icons-material/Pause';

import ReactPlayer from 'react-player'
import {StreamDetailsContextState} from "../../../../context/streamDetails.context";
import {ReplayVideoStateModel} from "../../../../models/replayVideoStateModel";
import clsx from "clsx";
import {AutoPlayContext} from "../../../../context/autoPlay.context";
import {VideoControls} from "../VideoControlsComponent";

interface VideoReplayProps {
    useTimestamps?: boolean;
    streamDetails: StreamDetailsContextState | undefined;
    streamId: string | undefined;
    showControls: boolean
    muted: boolean
    playing: boolean
    played: number
    handlePlayPause: (e: MouseEvent<HTMLElement>) => void;
    handleMuteUnmute: (e: MouseEvent<HTMLElement>) => void;
    handleSeekMouseDown: (e: MouseEvent<HTMLInputElement>) => void;
    handleSeekChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSeekMouseUp: (e: MouseEvent<HTMLInputElement>) => void;
    handleProgress: (e: ReplayVideoStateModel, useTimestamps: boolean, replayTime: number) => void;
    seekingTime: number;
    playedSeconds: number;
    volume: number;
    handleVolumeMouseDown: (e: MouseEvent<HTMLInputElement>) => void;
    handleVolumeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleVolumeMouseUp: (e: MouseEvent<HTMLInputElement>) => void;
    isPip: boolean | undefined;
    isRaceSchedule: boolean | undefined;
    isReplay?: boolean;
}

const VideoReplayComponent = ({
                                  useTimestamps,
                                  streamDetails,
                                  streamId,
                                  muted,
                                  playing,
                                  played,
                                  handlePlayPause,
                                  handleSeekMouseDown,
                                  handleSeekChange,
                                  handleSeekMouseUp,
                                  handleProgress,
                                  seekingTime,
                                  playedSeconds,
                                  handleMuteUnmute,
                                  volume,
                                  handleVolumeMouseDown,
                                  handleVolumeChange,
                                  handleVolumeMouseUp,
                                  showControls,
                                  isPip,
                                  isRaceSchedule,
                                  isReplay,
                              }: VideoReplayProps) => {

    const [showVolumeSlider, setShowVolumeSlider] = useState(false)
    const {setVideoDuration} = useContext(AutoPlayContext)

    const replayTimeRef = useRef(0);
    const videoElementRef = useRef<ReactPlayer>(null);

    useEffect(() => {
        if (!videoElementRef.current) return
        videoElementRef.current.seekTo(seekingTime);
    }, [seekingTime])

    useEffect(() => {
        if (videoElementRef.current) {
            setVideoDuration(videoElementRef.current.getDuration())
        }
    }, [played])

    useEffect(() => {

        if (!streamDetails || !streamDetails.replayStreams || !streamId) return;
        if (isReplay && !videoElementRef.current) {
            throw new Error('Tried to initialise Player before video element exists in DOM!');
        }
        //replay ref for data requests
        if (videoElementRef.current) {
            const startTimeStamp = DateTime.fromISO(streamDetails.replayStreams.startTime);
            replayTimeRef.current = startTimeStamp.toMillis();
            if (streamDetails?.replayStreams.insightsVideoStartOffsetSeconds && playedSeconds <= 0) {
                videoElementRef.current.seekTo(streamDetails?.replayStreams.insightsVideoStartOffsetSeconds);
            } else {
                if (playedSeconds > 0) {
                    videoElementRef.current.seekTo(playedSeconds);

                }
            }
        }

    }, [streamDetails, streamId, streamDetails?.replayStreams?.insightsVideoStartOffsetSeconds, isReplay]);

    return (
        <>
            <div className={clsx("videoControlsContainer", "replayControlsPosition", {"displayControls": showControls || !playing})}>
                {!isRaceSchedule &&
                  <div className="sliderContainer">
                    <input
                      className="scratchSlider"
                      type='range' min={0} max={0.999999} step='any'
                      value={played}
                      onMouseDown={handleSeekMouseDown}
                      onChange={handleSeekChange}
                      onMouseUp={handleSeekMouseUp}
                    />
                  </div>
                }
                <VideoControls playing={playing} isRaceSchedule={isRaceSchedule} handlePlayPause={handlePlayPause} setShowVolumeSlider={setShowVolumeSlider} muted={muted}
                              handleMuteUnmute={handleMuteUnmute} showVolumeSlider={showVolumeSlider} volume={volume} handleVolumeMouseDown={handleVolumeMouseDown}
                              handleVolumeChange={handleVolumeChange} handleVolumeMouseUp={handleVolumeMouseUp} showPlayPause={true}
                />

            </div>

            <div className="videoWrapper">
                {isReplay && (
                    <ReactPlayer
                        width="100%"
                        height="100%"
                        className={`disableControls videoContainerAbsolute ${isRaceSchedule ? 'videoContainerAbsolute--raceSchedule' : ''}`}
                        ref={videoElementRef}
                        playing={playing}
                        url={streamId}
                        muted={muted}
                        controls={false}
                        volume={useTimestamps ? volume : 0}
                        loop={false}
                        onProgress={(state) => handleProgress(state, !!useTimestamps && !isPip, replayTimeRef.current)}
                        onError={(a, b) => console.warn('error', a, b)}
                    />
                )}
            </div>

        </>


    )
};

export default VideoReplayComponent;
