import React, {PropsWithChildren, useContext, useMemo, useEffect} from 'react'
import {RaceDashboardDefinition, RaceDashboardWidgetDefinitions, RaceDashboardLayoutContext} from "./raceDashboardLayout.context";
import raceManagementLayout from "../utils/layout/raceManagementDashboardLayout.json";
import authRaceManagementLayout from "../utils/layout/authRaceManagementDashboardLayout.json";
import videoLayout from "../utils/layout/videoDashboardLayout.json";
import chatterLayout from "../utils/layout/chatterDashboardLayout.json";
import authChatterLayout from "../utils/layout/authChatterDashboardLayout.json";
import BoatGraphProgressWidget from "../widgets/BoatGraphProgressWidget";
import CombinedBoatStatWidget from "../widgets/CombinedBoatStatWidget";
import LiveInformationWidget from "../widgets/LiveInformationWidget";
import AlertWidget from "../widgets/AlertWidget";
import RaceCounterWidget from "../widgets/RaceCounterWidget";
import TimeToStartWidget from "../widgets/TimeToStartWidget";
import PredictedToFinishWidget from "../widgets/PredictedToFinishWidget";
import VideoWidget, {VideoWidgetDefaultStreamType} from "../widgets/VideoWidget/VideoContainer";
import {CurrentRaceEventContext} from "./currentRaceEvent.context";
import {createEventStreamsLayout} from "../utils/helpers/createEventStreamsLayout";
import EventStreamWidget from "../widgets/EventStreamWidget";


export const RaceDashboardLayoutProvider = ({children}: PropsWithChildren<{}>) => {
    const {streamsAvailableToUser} = useContext(CurrentRaceEventContext)

    const raceDashboardDefinitions = useMemo((): RaceDashboardDefinition[] => {
        return [
            {
                path: '/raceManagement',
                isAuthenticated: false,
                layout: raceManagementLayout,
                numRows: 24
            },
            {
                path: '/raceManagement',
                isAuthenticated: true,
                layout: authRaceManagementLayout,
                numRows: 24
            },
            {
                path: '/video',
                layout: videoLayout,
                numRows: 24
            },
            {
                path: '/chatter',
                isAuthenticated: true,
                layout: authChatterLayout,
                numRows: 24
            },
            {
                path: '/chatter',
                isAuthenticated: false,
                layout: chatterLayout,
                numRows: 24
            },
            {
                path: '/eventStreams',
                layout: createEventStreamsLayout(streamsAvailableToUser),
                numRows: 24
            }
        ];
    }, [streamsAvailableToUser])

    const raceDashboardWidgets = useMemo(() : RaceDashboardWidgetDefinitions => {
        let raceDashboardWidgetsBase: RaceDashboardWidgetDefinitions = {
            progressGraph: () => <BoatGraphProgressWidget/>,
            combinedStats: () => <CombinedBoatStatWidget/>,
            keyInformation: () => <LiveInformationWidget/>,
            alerts: () => <AlertWidget/>,
            raceCounter: () => <RaceCounterWidget/>,
            timeToStart: () => <TimeToStartWidget/>,
            predictedToFinish: () => <PredictedToFinishWidget/>,
            video1: () => <VideoWidget
                useTimestamps
                defaultStreamType={VideoWidgetDefaultStreamType.WorldFeed}
                video={1}
            />,
            video2: () => <VideoWidget
                defaultStreamType={VideoWidgetDefaultStreamType.Umpapp}
                video={2}
                />
        }

        streamsAvailableToUser?.forEach((widget, i) => {
            if(!raceDashboardWidgetsBase[`eventStreamVideo${i + 1}`]){
                raceDashboardWidgetsBase = {
                    ...raceDashboardWidgetsBase,
                    [`eventStreamVideo${i + 1}`]: () => <EventStreamWidget
                        streamIndex={i}
                    />,
                }
            }

        })

        return raceDashboardWidgetsBase
    }, [streamsAvailableToUser])

    const dashboardRoutePaths = useMemo(() => {
        return raceDashboardDefinitions.map(def => def.path)
    }, [raceDashboardDefinitions])

    return (
        <RaceDashboardLayoutContext.Provider value={{raceDashboardWidgets, raceDashboardDefinitions, dashboardRoutePaths}}>
            {children}
        </RaceDashboardLayoutContext.Provider>
    )
}