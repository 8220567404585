import React, {useEffect, useState} from "react";
import "../Login/Forms.scss";
import {useController, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {submitForm, UserVerificationData} from "../../utils/helpers/submitForm";
import DatePicker from 'react-datepicker'
import {useHistory} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {format} from "date-fns";
import {fetchFromContentProxy} from "../../utils/helpers/fetchFromContentProxy";
import {LogoContainer} from "../../components/Logo/LogoContainer";

interface Country {
    contentfulId: string;
    contentfulType: string;
    created: string;
    iso: string;
    name: string;
    updated: string;
}

const RegisterPage = () => {
    const {register, getValues, handleSubmit, formState: {errors}, control} = useForm({mode: 'onBlur'});
    const navigate = useHistory();
    const {field: {onChange, value}} = useController({name: 'dateOfBirth', control: control})
    const [countries, setCountries] = useState<Country[] | null>(null)

    useEffect(() => {
        (async () => {
            const countryList: Country[] = await fetchFromContentProxy('country');
            setCountries(countryList)
        })()
    }, [])

    const onSubmit = async (data: any) => {
        let registerData = {
            ...data,
            applicationName: process.env.REACT_APP_APPLICATION_NAME,
            gdprConsent:  data.newsletterConsent
        };
        const loginToast = toast.loading('Loading...');
        if (process.env.REACT_APP_REGISTER_URL) {
            delete registerData.repeatPassword;
            registerData.dateOfBirth = format(registerData.dateOfBirth, 'yyyy-MM-dd')
            const registerResponse = await submitForm(registerData, process.env.REACT_APP_REGISTER_URL)

            if (registerResponse && (registerResponse["status"] !== undefined && registerResponse["status"] === "success")) {
                await sendNotificationVerifyEmail(registerData)
                toast.dismiss(loginToast)
            } else if (registerResponse && registerResponse['status'] !== undefined && registerResponse['status'] === 'error' && registerResponse['errorDescription'] !== undefined) {
                toast.dismiss(loginToast)
                toast.error(registerResponse['errorDescription'], {
                    autoClose: 2000,
                    position: toast.POSITION.TOP_CENTER
                });
            } else if (registerResponse && registerResponse['status'] !== undefined && registerResponse['status'] === 'failed' && registerResponse['error_description'] !== undefined) {
                toast.dismiss(loginToast)
                toast.error(registerResponse['error_description'], {
                    autoClose: 2000,
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.dismiss(loginToast)
                toast.error('Server Error!', {
                    autoClose: 2000,
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }

    async function sendNotificationVerifyEmail(submitData: any) {
        const userVerificationData: UserVerificationData = {
            firstName: submitData.firstName,
            redirectURL: `${window.location.origin}/login`,
            emailTemplate: "InsightsUserVerification",
            recipient: submitData.emailAddress,
            tokenUrl: "email/generate-token",
            userName: submitData.emailAddress,
            operation: "VERIFY_USER"
        };
        try {
            const emailResponse = await submitForm(userVerificationData, 'email/verify-user-email')
            if (emailResponse) {
                navigate.push("/email-verification");
            }
        } catch (e) {
            // Error
        }
    }

    return (
        <div className="insightsBackgroundContainer">
            <LogoContainer extraMarginTop={true}/>
            <div className="registerFormContainer">
                <div className='insightsForm__infoContainer'>
                    <h1 className="insightsForm__title">Sign up for free.</h1>
                    <p className="insightsForm__text insightsForm__text-info">
                        Get rewarded for interacting with all things SailGP+ and earn exclusive
                        experiences, discounts and more.
                    </p>
                </div>
                <p className="insightsForm__text insightsForm__text-bold">Sign up with your e-mail address</p>
                <form className='insightsForm' onSubmit={handleSubmit(onSubmit)}>
                    <label className="insightsForm__label" htmlFor="firstName">
                        First Name
                    </label>
                    <input
                        className={`insightsForm__input ${
                            errors.firstName ? "insightsForm__input--error" : ""
                        } `}
                        type="text"
                        {...register("firstName", {
                            required: true,
                            pattern: /^[a-z ,.'-]+$/i
                        })}
                    />
                    {errors.firstName && errors.firstName.type === 'required' && (
                        <p className='insightsForm__error'>This field is required.</p>
                    )}
                    {errors.firstName && errors.firstName.type === 'pattern' && (
                        <p className='insightsForm__error'>Please enter a valid first name.</p>
                    )}

                    <label className="insightsForm__label" htmlFor="lastName">
                        Last Name
                    </label>
                    <input
                        className={`insightsForm__input ${
                            errors.lastName ? "insightsForm__input--error" : ""
                        } `}
                        type="text"
                        {...register("lastName", {
                            required: true,
                            pattern: /^[a-z ,.'-]+$/i
                        })}
                    />
                    {errors.lastName && errors.lastName.type === 'required' && (
                        <p className='insightsForm__error'>This field is required.</p>
                    )}
                    {errors.lastName && errors.lastName.type === 'pattern' && (
                        <p className='insightsForm__error'>Please enter a valid last name.</p>
                    )}


                    <label className="insightsForm__label" htmlFor="emailAddress">
                        Email Address
                    </label>
                    <input
                        className={`insightsForm__input ${
                            errors.emailAddress ? "insightsForm__input--error" : ""
                        } `}
                        type="text"
                        {...register("emailAddress", {
                            required: true,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        })}
                    />

                    {errors.emailAddress && errors.emailAddress.type === 'required' && (
                        <p className='insightsForm__error'>This field is required.</p>
                    )}
                    {errors.emailAddress && errors.emailAddress.type === 'pattern' && (
                        <p className='insightsForm__error'>Please enter a valid email address.</p>
                    )}
                    <label className="insightsForm__label" htmlFor="dateOfBirth">
                        Date of Birth
                    </label>
                    <DatePicker
                        name='dateOfBirth'
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                        minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 120))}
                        selected={value}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        dateFormat={'dd/MM/yyyy'}
                        onChange={(date) => onChange(date)}
                        placeholderText='DD/MM/YYYY'
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                    <label className="insightsForm__label" htmlFor="country">Country</label>
                    <select
                        className={`insightsForm__input ${errors.country ? "insightsForm__input--error" : ""} `}
                        {...register('country', {required: true})}>
                        <option
                            value=""
                            className="select-option"
                        >
                            -- Please select --
                        </option>
                        {countries && countries.map(country => {
                            return <option key={country.contentfulId} value={country.iso}>
                                {country.name}
                            </option>
                        })}</select>
                    {errors.country && errors.country.type === 'required' && (
                        <p className='insightsForm__error'>This field is required.</p>
                    )}
                    <label className="insightsForm__label" htmlFor="password">
                        Password
                    </label>
                    <input
                        className={`insightsForm__input ${
                            errors.password ? "insightsForm__input--error" : ""
                        } `}
                        type="password"
                        {...register("password", {
                            required: true,
                            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/g,
                        })}
                    />
                    {errors.password && errors.password.type === 'required' && (
                        <p className='insightsForm__error'>This field is required.</p>
                    )}
                    {errors.password && errors.password.type === 'pattern' && (
                        <p className='insightsForm__error'>The password must be at least 8 characters, contain an
                            uppercase
                            letter, lowercase letter and a number.</p>
                    )}

                    <label className="insightsForm__label" htmlFor="repeatPassword">
                        Repeat Password
                    </label>
                    <input
                        className={`insightsForm__input ${
                            errors.password ? "insightsForm__input--error" : ""
                        } `}
                        type="password"
                        {...register("repeatPassword", {
                            required: true,
                            validate: (value) =>
                                value === getValues("password")

                        })}
                    />

                    {errors.repeatPassword && errors.repeatPassword.type === 'required' && (
                        <p className='insightsForm__error'>This field is required.</p>
                    )}
                    {errors.repeatPassword && errors.repeatPassword.type === 'validate' && (
                        <p className='insightsForm__error'>Passwords do not match.</p>
                    )}
                    <label className="insightsForm__label insightsForm__label--not-required"
                           htmlFor="marketingPreferences">
                        Marketing Peferences
                    </label>

                    <p className="insightsForm__checkbox-text">
                        <input type="checkbox"
                               {...register("newsletterConsent", {})}
                               className="insightsForm__custom-checkbox"
                        />
                        I want to hear from SailGP about exclusive content, team news, events and tickets.</p>

                    <label className="insightsForm__label insightsForm__label--not-required">Privacy Policy</label>
                    <p className="insightsForm__checkbox-text"> Please read our &nbsp;
                        <a className="insightsForm__link"
                           target="_blank" rel="noreferrer"
                           href='https://sailgp.com/general/privacy/'>Privacy Policy</a> to
                        understand how SailGP uses your data and how you can change your marketing preferences at any
                        time.</p>
                    <label className="insightsForm__label insightsForm__label--not-required"
                           htmlFor="tAndCAccepted">
                        Terms and Conditions <span className="insightsForm__required">*</span>
                        <p className="insightsForm__checkbox-text">
                            <input type="checkbox"
                                   {...register("tAndCAccepted", {
                                       required: true,

                                   })}
                                   className="insightsForm__custom-checkbox"
                            />
                            By signing up to SailGP Insights you confirm that you have read and agree to the &nbsp;
                            <a className="insightsForm__link"
                               target="_blank" rel="noreferrer"
                               href="https://sailgp.com/terms/">Terms of Use</a>
                        </p>
                    </label>
                    {errors.tAndCAccepted && errors.tAndCAccepted.type === 'required' && (
                        <p className='insightsForm__error'>This field is required.</p>
                    )}
                    <div className="insightsForm__buttonContainer">
                        <button
                            className={`insightsForm__submit ${
                                Object.keys(errors).length > 0
                                    ? "insightsForm__submit--disabled"
                                    : ""
                            }`}
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            disabled={Object.keys(errors).length > 0}
                        >
                            <p className="insightsForm__email">Continue</p>
                            <img
                                className="insightsForm__icon"
                                src={
                                    Object.keys(errors).length > 0
                                        ? "/Sign_in_circle_disabled.svg"
                                        : "/Sign_in_circle_enabled.svg"
                                }
                                alt=""
                            />
                        </button>
                    </div>

                </form>
            </div>

        </div>
    );
};
export default RegisterPage;
