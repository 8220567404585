import {User} from "../../models/enums/userModel";

interface configSettingInterface {
    method: string,
    credentials: "include" | "omit" | "same-origin" | undefined,
    headers: {
        Accept: string,
        'Content-Type': string
    },
}

interface PostConfigSettingInterface extends configSettingInterface {
    body?: string
}

interface LoginDataBodyInterface {
    emailAddress: string,
    password: string
    successUrl: string,
    successUrl2: string
}

interface ResetPasswordBodyInterface {
    Session: boolean,
    Token: string,
    UserName: string,
    newPassword: string,
    repeatPassword: string
}

interface ForgotPasswordBodyInterface {
    email: string
}

interface SendNotificationBodyInterface {
    Parameters: [
        {
            Value: string,
            Key: string
        }],
    EmailTemplate: string,
    Recipient: string
}


let configSettingGet: configSettingInterface = {
    method: 'GET',
    credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
}

export interface UserVerificationData {
    firstName: string,
    redirectURL: string,
    emailTemplate: string,
    recipient: string,
    tokenUrl: string,
    userName: string,
    operation: string
}

export interface VerifyUserData {
    Token: string
}

const configSettingPost: PostConfigSettingInterface = {
    method: 'POST',
    credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
};

const baseUrl = `${process.env.REACT_APP_USER_SERVICE_BASE_API_URL}${process.env.REACT_APP_CONTENT_DOCK_CONTEXT}`


export const submitForm = async (formData: LoginDataBodyInterface | ForgotPasswordBodyInterface | ResetPasswordBodyInterface | UserVerificationData | VerifyUserData | SendNotificationBodyInterface | User | {}, submitUrl: string) => {
    configSettingPost.body = JSON.stringify(formData)
    console.log('baseUrl', baseUrl)
    try {
        const response = await fetch(`${baseUrl}${submitUrl}`, configSettingPost)
        return await response.json()
    } catch (error) {
        return error
    }
}

export const submitLogOut = async (submitUrl: string): Promise<{ status: number } | unknown> => {

    try {
        const response = await fetch(`${baseUrl}${submitUrl}`,configSettingPost)
        return response;
    } catch (error) {
        return error
    }
}

export const formatResetPasswordBody = (submitData: { newPassword: string, repeatPassword: string }, user: User) => {
    const search = window.location.search;
    if (search.length !== 0 && !user) {
        let params = new URLSearchParams(search);
        const userNameParams = params.get('userName') || ''
        const tokenParams = params.get('token') || ''
        let UserName = decodeURIComponent(userNameParams);
        let Token = decodeURIComponent(tokenParams);
        if (UserName && Token) {
            return {...submitData, Token, UserName, Session: false}
        }
    }
    else{
        submitData["UserName"] = user["emailAddress"];
        submitData["Session"]=true;
        return {...submitData}
       
    }
}

export async function submitFormNoBody(submitUrl: string) {

    try {
        const response = await fetch(`${baseUrl}${submitUrl}`, configSettingGet)
        const json = await response.json();
        return json;
    } catch (error) {
        return error;
    }
}

export async function ssoLogin() {

	configSettingPost['body'] = JSON.stringify({landingUrl : baseUrl});
	let submitUrl = "user/sso-login";
	try {
		const fetchResponse = await fetch(`${baseUrl}${submitUrl}` , configSettingPost);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}