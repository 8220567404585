import React, { useContext, useEffect, useState } from "react";
import { DashboardHeader } from "../../components/DashboardHeader";
import "./RaceSchedule.scss";
import { CurrentRaceEventContext } from "../../context/currentRaceEvent.context";
import {
  ContentProxyRaceDay,
  ContentProxyRaceDayStatus,
  ContentProxyRaceEvent,
} from "../../models/contentProxyModel";
import { useHistory } from "react-router-dom";
import { FullLoadingIndicator } from "../../components/FullLoadingIndicator/FullLoadingIndicator";
import RaceScheduleWidget from "../../widgets/RaceScheduleWidget";
import { ScheduleFooter } from "../../components/ScehduleFooter";
import SliderComponent from "./ScheduleSlider/SliderComponent";
import { NavigationSliderContext } from "../../context/navigationSlider.context";
import { VideoReplaySyncContext } from "../../context/videoReplaySync.context";
import CookieComponent from "../../components/CookieComponent";
import { useLocalStorageState } from "../../hooks/useLocalStorageState";
import AdditionalUserDataModal from "../../components/AdditionalUserDataModal";

export const RaceSchedule = () => {
  const history = useHistory();
  const { currentSlide, handleSelect } = useContext(NavigationSliderContext);
  const {
    switchRaceEvent,
    allRaceEvents,
    isLoading,
    nextEvent,
    selectedRaceDayId,
    broadcasterRegionalConfig,
    isRaceFinished,
  } = useContext(CurrentRaceEventContext);
  const { switchEventAndResetTime } = useContext(VideoReplaySyncContext);
  const [acceptedPrivacyPolicy] = useLocalStorageState<boolean>(
    "acceptedPrivacyPolicy",
    false
  );
  const [openPrivacyModal, setOpenPrivacyModal] = useState<boolean>(false);
  const [separatedSeasons, setSeparatedSeasons] = useState<object>({});
  const [seasonNumbers, setSeasonNumbers] = useState<string[]>([]);
  const [activeSlide, setActiveSlide] = useState<
    ContentProxyRaceEvent | undefined
  >(undefined);
  const [showWatchButton, setShowWatchButton] = useState<boolean>(true);

  useEffect(() => {
    const seasonsObj =
      allRaceEvents?.reduce((accumulator, raceEvent) => {
        if (
          accumulator[raceEvent.eventSeason] &&
          accumulator[raceEvent.eventSeason].races
        ) {
          accumulator[raceEvent.eventSeason].races.push(raceEvent);
        } else accumulator[raceEvent.eventSeason] = { races: [raceEvent] };
        return accumulator;
      }, {}) || {};

    for (const key in seasonsObj) {
      let eventSlide = 0;
      if (key === Object.keys(seasonsObj)[Object.keys(seasonsObj).length - 1])
        eventSlide = seasonsObj[key].races.reduce(
          (
            lastMatch: number | null,
            item: ContentProxyRaceEvent,
            currentIndex: number
          ) => {
            const raceDayWithReplay =
              item.raceDays &&
              item.raceDays.find((raceDay: ContentProxyRaceDay) => {
                return (
                  raceDay.state === ContentProxyRaceDayStatus.ReplayAvailable || raceDay.state === ContentProxyRaceDayStatus.InProgress
                );
              });
            if (raceDayWithReplay) setActiveSlide(item);
            return raceDayWithReplay ? currentIndex : lastMatch;
          },
          null
        );
      seasonsObj[key].initialSlide = eventSlide;
      seasonsObj[key].slidesToShow = seasonsObj[key].races.length > 5 ? 6 : 5;
    }

    setSeparatedSeasons(seasonsObj);
    setSeasonNumbers(Object.keys(seasonsObj).reverse());
  }, [allRaceEvents]);

  useEffect(() => {
    if (!acceptedPrivacyPolicy && !isLoading) {
      setOpenPrivacyModal(true);
    }
  }, [isLoading, acceptedPrivacyPolicy]);

  const changeRaceEvent = (
    eventId: string,
    raceDay: ContentProxyRaceDay,
    isLiveRaceDay: boolean
  ) => {
    switchRaceEvent(eventId, raceDay.contentfulId, isLiveRaceDay);
    switchEventAndResetTime();
    history.push("/raceManagement");
  };

  useEffect(() => {
    const unlisten = history.listen((newLocation, action) => {
      if (action === "POP") {
        // If a "POP" action event occurs, and the race is in Finished state
        // Prevent user from going back
        // Otherwise send user back to the originating location
        if (isRaceFinished) history.go(1);
        else history.go(-1);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <div className="raceScheduleContainer">
      {isLoading && !currentSlide && <FullLoadingIndicator />}
      <div className="headerContainer">
        <DashboardHeader
          eventId={currentSlide?.contentfulId}
          leftContent={<RaceScheduleWidget isInRaceSchedule={true} />}
        />
      </div>
      <CookieComponent
        acceptPrivacyPolicy={openPrivacyModal}
        handlePrivacyModalToggle={() => setOpenPrivacyModal(false)}
      />
      {allRaceEvents && (
        <SliderComponent
          activeSlide={currentSlide ? currentSlide : activeSlide}
          allRaceEvents={allRaceEvents}
          changeRaceEvent={changeRaceEvent}
          nextEvent={nextEvent}
          currentSlide={currentSlide}
          handleSelect={handleSelect}
          selectedRaceDayId={selectedRaceDayId}
          broadcasterRegionalConfig={broadcasterRegionalConfig}
          separatedSeasons={separatedSeasons}
          seasonNumbers={seasonNumbers}
          switchRaceEvent={switchRaceEvent}
          switchEventAndResetTime={switchEventAndResetTime}
          showWatchButton={showWatchButton}
          setShowWatchButton={setShowWatchButton}
        />
      )}
      <AdditionalUserDataModal />
      <ScheduleFooter />
    </div>
  );
};
