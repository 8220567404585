import React, {useEffect} from "react";
import ChatterApp from "./ChatterApp";
import {WebWorkerProvider} from "./context/webWorkerProvider";
import {initGA} from "./index";
import {CurrentRaceEventProvider} from "./context/currentRaceEventProvider";
import {LoginProvider} from "./context/login.context";
import {StreamDetailsProvider} from "./context/StreamDetailsProvider";
import {StyledEngineProvider, Theme, ThemeProvider} from "@mui/material";
import {darkTheme} from "./styles/themes/darkTheme";
import {BoatCountryCodesProvider} from "./context/BoatCountryCodesProvider";
import {VideoReplaySyncProvider} from "./context/videoReplaySync.provider";
import {NavigationSliderProvider} from "./context/navigationSliderProvider";
import {useWindowSize} from "./hooks/useWindowSize";
import {MobileDownloadPrompt} from "./components/MobileDownloadPrompt";
import RouteChangeTracker from "./utils/routeChangeTracker";
import AutoPlayProvider from "./context/autoPlayProvider";
import {RaceDashboardLayoutProvider} from "./context/RaceDashboardLayoutProvider";
import {TeamDataProvider} from "./context/TeamDataProvider";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {
    }
}

const App = () => {
    useEffect(() => {
        initGA();
    }, []);

    const {width} = useWindowSize();

    // on mobile devices we should be pushing users to the App
    return width <= 1024 || !width ? (
        <MobileDownloadPrompt/>
    ) : (
        <LoginProvider>
            <BoatCountryCodesProvider>
                <WebWorkerProvider>
                    <CurrentRaceEventProvider>
                        <TeamDataProvider>
                            <NavigationSliderProvider>
                                <StreamDetailsProvider>
                                    <VideoReplaySyncProvider>
                                        <StyledEngineProvider injectFirst>
                                            <RaceDashboardLayoutProvider>
                                                <AutoPlayProvider>

                                                        <ThemeProvider theme={darkTheme}>
                                                            <RouteChangeTracker/>
                                                            <ChatterApp/>
                                                        </ThemeProvider>

                                                </AutoPlayProvider>
                                            </RaceDashboardLayoutProvider>
                                        </StyledEngineProvider>
                                    </VideoReplaySyncProvider>
                                </StreamDetailsProvider>
                            </NavigationSliderProvider>
                        </TeamDataProvider>
                    </CurrentRaceEventProvider>
                </WebWorkerProvider>
            </BoatCountryCodesProvider>
        </LoginProvider>
    );
};

export default App;
