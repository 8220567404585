import React, {
    ChangeEvent,
    MouseEvent,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import './VideoLiveComponent.scss';
import {useStableCallback} from "../../../../hooks/useStableCallback";
import {CurrentRaceEventContext} from "../../../../context/currentRaceEvent.context";
import {ContentProxyRaceDay} from "../../../../models/contentProxyModel";
import {getSelectedRaceDay} from "../../../../utils/helpers/getSelectedRaceDay";
import {Vindral} from "@vindral/web-sdk"
import "@vindral/web-sdk/style.css"
import {VideoControls} from "../VideoControlsComponent";
import {ReplayVideoStateModel} from "../../../../models/replayVideoStateModel";
import clsx from "clsx";
import {CommentaryEnum, CommentaryType} from "../../../../context/StreamDetailsProvider";

interface VideoLiveProps {
    useTimestamps?: boolean;
    setTimestamp: (time: number) => void;
    streamDetails: any;
    streamId: any;
    isRaceSchedule: boolean | undefined;
    showControls: boolean
    muted: boolean
    playing: boolean
    handlePlayPause: (e: MouseEvent<HTMLElement>) => void;
    handleMuteUnmute: (e: MouseEvent<HTMLElement>) => void;
    handleProgress: (e: ReplayVideoStateModel, useTimestamps: boolean, replayTime: number) => void;
    volume: number;
    handleVolumeMouseDown: (e: MouseEvent<HTMLInputElement>) => void;
    handleVolumeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleVolumeMouseUp: (e: MouseEvent<HTMLInputElement>) => void;
    isReplay?: boolean;
    allowFullScreen?: boolean
}

const VideoLiveComponent = ({
                                useTimestamps,
                                setTimestamp,
                                streamDetails,
                                streamId,
                                handleVolumeMouseUp,
                                isRaceSchedule,
                                handlePlayPause,
                                playing,
                                muted,
                                handleMuteUnmute,
                                volume,
                                handleVolumeMouseDown,
                                handleVolumeChange,
                                showControls,
                            }: VideoLiveProps) => {
        const containerElementRef = useRef<HTMLDivElement | null>(null);
        const playerRef = useRef<Vindral>();
        const podcastPlayerRef = useRef<Vindral>();
        const podcastContainerElementRef = useRef<HTMLDivElement | null>(null);


        const [showVolumeSlider, setShowVolumeSlider] = useState(false)
        const [isPodcastAvailable, setIsPodcastAvailable] = useState(false)

        const {currentEvent, raceType, selectedRaceDayId} = useContext(CurrentRaceEventContext);
        const currentRaceDay = useMemo((): ContentProxyRaceDay | undefined => {
            if (!currentEvent) {
                return undefined;
            }
            return getSelectedRaceDay(currentEvent, raceType, selectedRaceDayId);
        }, [currentEvent, raceType, selectedRaceDayId]);


        const onTimestampReceived = useStableCallback((timestamp: number) => {
            if (useTimestamps && timestamp) {
                setTimestamp(timestamp);
            }
        });

        //live data only display for regions with 0 streams
        useEffect(() => {
            const interval = setInterval(() => {
                if (streamDetails && streamDetails.streamIds.length === 0 && currentRaceDay) {
                    const currentTime = new Date().valueOf() - streamDetails.dataOnlyDelayMillis
                    onTimestampReceived(currentTime);
                }
            }, 500);
            return () => clearInterval(interval);
        }, [streamDetails])


        useEffect(() => {
            if (streamDetails?.host && streamId && streamDetails.streamIds.length) {
                
                streamDetails.handleCommentarySelect({key: CommentaryEnum.Commentary, isSelected: true})
                const player = new Vindral(
                    {
                        url: streamDetails.host,
                        channelId: streamId,
                        minBufferTime: 1000,
                        maxBufferTime: 2000,
                        pictureInPictureEnabled: false,
                        burstEnabled: true,
                    }
                )
                player.play();
                player.attach(containerElementRef.current!);
                player.on("server wallclock time", (timestamp: any) => {
                    if (timestamp) {
                        const requestTimeStampMillisecondsWithOffset = +timestamp - streamDetails.insightsWebLatencyMilliseconds
                        onTimestampReceived(requestTimeStampMillisecondsWithOffset);
                    }
                })

                playerRef.current = player;
                playerRef.current.volume = useTimestamps ? volume : 0
                return () => {
                    if (playerRef.current) {
                        playerRef.current.unload();
                        playerRef.current = undefined;
                    }
                };
            }
        }, [streamDetails?.host, streamId, streamDetails?.insightsWebLatencyMilliseconds])


        useEffect(() => {
            if (streamDetails?.host && streamId && streamDetails.streamIds.length && streamDetails.commentaryType) {
                const selectedCommentary = streamDetails.commentaryType.find((type: CommentaryType) => type.isSelected);

                //todo maybe put the in contentful in the future if this feature continues
                const podcast = streamDetails.streamIds.filter((val: string) => val === 'tata_sailgpspanishtv_ci_4bc9c1e6-bc42-4baf-9b29-fef6260933d4')[0]

                if (podcast) {
                    setIsPodcastAvailable(true)
                    const podCastPlayer = new Vindral(
                        {
                            url: streamDetails.host,
                            channelId: podcast,
                            media: "audio",
                        }
                    )

                    if (selectedCommentary?.key === CommentaryEnum.Podcast) {
                        podCastPlayer.attach(podcastContainerElementRef.current!)
                        podCastPlayer.play()
                        podcastPlayerRef.current = podCastPlayer
                    }

                    return () => {
                        if (podcastPlayerRef.current) {
                            podcastPlayerRef.current.unload(); // Proper cleanup when necessary
                            podcastPlayerRef.current = undefined; // Only if you're sure you don't need it anymore
                        }
                    };
                }
            }
        }, [streamDetails?.commentaryType, streamDetails?.host, streamDetails?.streamIds, streamId])

        useEffect(() => {
            if (playerRef.current && !podcastPlayerRef.current) {
                playerRef.current.volume = useTimestamps ? volume : 0
                playerRef.current.muted = muted
            }

            if (podcastPlayerRef.current && playerRef.current) {
                podcastPlayerRef.current.volume = useTimestamps ? volume : 0
                podcastPlayerRef.current.muted = muted
                playerRef.current.volume = 0
                playerRef.current.muted = true
            }
        }, [volume, muted, streamDetails?.commentaryType])

        return (
            <>
                <div className="videoWrapper">
                    <div className="videoContainerAbsolute" style={{ height: '100%' }} ref={containerElementRef}>
                    </div>
                </div>

                <div ref={podcastContainerElementRef}>
                </div>

                <div
                    className={clsx("videoControlsContainer", "liveControlsPosition", {"displayControls": showControls || !playing})}>
                    <VideoControls playing={playing} isRaceSchedule={isRaceSchedule} showPlayPause={false}
                                   setShowVolumeSlider={setShowVolumeSlider} muted={muted} handlePlayPause={handlePlayPause}
                                   handleMuteUnmute={handleMuteUnmute} showVolumeSlider={showVolumeSlider} volume={volume}
                                   handleVolumeMouseDown={handleVolumeMouseDown}
                                   handleVolumeChange={handleVolumeChange} handleVolumeMouseUp={handleVolumeMouseUp}
                                   streamDetails={streamDetails} isPodcastAvailable={isPodcastAvailable}
                    />

                </div>
            </>
        );
    }
;
export default VideoLiveComponent;