import React, {
    ChangeEvent,
    MouseEvent,
    useEffect,
    useState,
} from "react";
import "./VideoActions.scss";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {Box, Button, Modal} from "@mui/material";
import {CommentaryType} from "../../../../context/StreamDetailsProvider";
import {StreamDetailsContextState} from "../../../../context/streamDetails.context";
import {makeStyles} from "@mui/styles";

export interface VideoControlsProps {
    isRaceSchedule: boolean | undefined;
    muted: boolean;
    playing: boolean;
    handlePlayPause: (e: MouseEvent<HTMLElement>) => void;
    handleMuteUnmute: (e: MouseEvent<HTMLElement>) => void;
    volume: number;
    handleVolumeMouseDown: (e: MouseEvent<HTMLInputElement>) => void;
    handleVolumeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleVolumeMouseUp: (e: MouseEvent<HTMLInputElement>) => void;
    isReplay?: boolean;
    showPlayPause?: boolean;
    showVolumeSlider: boolean;
    isPodcastAvailable?: boolean;
    setShowVolumeSlider: React.Dispatch<React.SetStateAction<boolean>>;
    streamDetails?: StreamDetailsContextState;
}
export const VideoControls = ({
                                  handleVolumeMouseUp,
                                  isRaceSchedule,
                                  handlePlayPause,
                                  playing,
                                  setShowVolumeSlider,
                                  muted,
                                  handleMuteUnmute,
                                  showVolumeSlider,
                                  volume,
                                  handleVolumeMouseDown,
                                  handleVolumeChange,
                                  showPlayPause = false,
                                  streamDetails,
                                  isPodcastAvailable = false,
                              }: VideoControlsProps) => {

    return (
        <div className="videoActionsContainer">
            {!isRaceSchedule && showPlayPause && (
                <Tooltip title={!playing ? "Play" : "Pause"} placement="top">
                    <IconButton
                        onClick={(e) => {
                            handlePlayPause(e);
                        }}
                    >
                        {!playing ? <PlayArrowIcon/> : <PauseIcon/>}
                    </IconButton>
                </Tooltip>
            )}

            <div
                className="volumeContainer"
                onMouseEnter={() => setShowVolumeSlider(true)}
                onMouseLeave={() => setShowVolumeSlider(false)}
            >
                <Tooltip title={!muted ? "Mute" : "Unmute"} placement="top">
                    <IconButton onClick={handleMuteUnmute}>
                        {!muted ? <VolumeUpIcon/> : <VolumeOffIcon/>}
                    </IconButton>
                </Tooltip>
                {showVolumeSlider && (
                    <input
                        className="volumeSlider"
                        type="range"
                        min={0}
                        max={1}
                        step="any"
                        value={volume}
                        onMouseDown={handleVolumeMouseDown}
                        onChange={handleVolumeChange}
                        onMouseUp={handleVolumeMouseUp}
                    />
                )}
            </div>

            {streamDetails?.commentaryType && isPodcastAvailable && (
                <div className="commentaryTypeSelector">
                    {streamDetails.commentaryType.map((type: CommentaryType) => (
                        <Button
                            className={`commentary__button ${type.isSelected}`}
                            key={type.key}
                            variant={type.isSelected ? "contained" : "outlined"}
                            onClick={() =>
                                streamDetails.handleCommentarySelect
                                    ? streamDetails.handleCommentarySelect(type)
                                    : null
                            }
                        >
                            {type.key}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};
