import {toast} from "react-toastify";
import {submitFormNoBody} from "../../utils/helpers/submitForm";
import {useContext} from "react";
import {DashboardHeader} from "../../components/DashboardHeader";
import RaceScheduleWidget from "../../widgets/RaceScheduleWidget";

import "./DeleteAccount.scss";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {LoginContext} from "../../context/login.context";

const DeleteAccount = () => {
    const {handleSubmit} = useForm({mode: "onBlur"});
    const navigate = useHistory();
    const {setLoginStatus} = useContext(LoginContext);

    const onSubmit = async () => {
        const loginToast = toast.loading("Loading...");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");

        if (process.env.REACT_APP_DELETE_USER) {

            const deleteResponse = await submitFormNoBody(process.env.REACT_APP_DELETE_USER);

            if (
                deleteResponse &&
                deleteResponse["status"] !== undefined &&
                deleteResponse["status"] === "success"
            ) {
                toast.dismiss(loginToast);
                setLoginStatus(false, deleteResponse.userDetails);
                navigate.push("/");
            } else if (
                deleteResponse &&
                deleteResponse["status"] !== undefined &&
                deleteResponse["status"] === "error" &&
                deleteResponse["errorDescription"] !== undefined
            ) {
                toast.dismiss(loginToast);
                toast.error(deleteResponse["errorDescription"], {
                    autoClose: 2000,
                    position: toast.POSITION.TOP_CENTER,
                });
            } else if (
                deleteResponse &&
                deleteResponse["status"] !== undefined &&
                deleteResponse["status"] === "failed" &&
                deleteResponse["error_description"] !== undefined
            ) {
                toast.dismiss(loginToast);
                toast.error(deleteResponse["error_description"], {
                    autoClose: 2000,
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.dismiss(loginToast);
                toast.error("Server Error!", {
                    autoClose: 2000,
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }

    };

    return (
        <div className="raceScheduleContainer">
            <DashboardHeader
                leftContent={<RaceScheduleWidget isInRaceSchedule={true}/>}
            />

            <div className="deleteFormContainer">
                <div className="l-grid__inner">
                    <div className="l-grid__cell l-grid__cell--span-2-xl l-grid__cell--span-2-lg"></div>
                    <div
                        className="l-grid__cell l-grid__cell--span-8-xl l-grid__cell--span-8-lg l-grid__cell--span-6-md l-grid__cell--span-2-sm">
                        <p className="delete-text__section__heading">ARE YOU SURE?</p>
                        <div className="delete-text">
                            <p className="delete-text--subheading">
                                Before deleting your account, please consider the following:
                            </p>
                            <ul>
                                <li>
                                    Account deletion is final. There is no way to restore your
                                    account.
                                </li>
                                <li>
                                    We will delete your account and data as soon as we acknowledge
                                    your request. However, this could take up to 24 hours. Once
                                    deletion is complete we will confirm via email.
                                </li>
                                <li>
                                    Please contact{" "}
                                    <a href="mailto:app@sailgp.com">app@sailgp.com</a> if you have
                                    any questions or wish to provide feedback.
                                </li>
                            </ul>
                            <p>
                                We value your support and hope you remain a part of our app
                                community.
                            </p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="submit">
                                <button
                                    className="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    type="submit"
                                >
                                    <p className="insightsForm__email">Confirm</p>
                                    <img
                                        className="insightsForm__icon"
                                        src={"/Sign_in_circle_enabled.svg"}
                                        alt=""
                                    />
                                </button>

                                <a
                                    href={"/schedule"}
                                    className="insightsForm__button insightsForm__button--primary deleteForm__button"
                                    aria-label="Reset Password"
                                >
                  <span className="insightsForm__button__label">
                    I WANT TO KEEP MY ACCOUNT
                  </span>
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DeleteAccount;
