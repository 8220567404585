import React, {useContext} from 'react';
import {useForm} from "react-hook-form";
import {LoginContext} from "../../context/login.context";
import {formatResetPasswordBody, submitForm} from "../../utils/helpers/submitForm";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import { DashboardHeader } from "../../components/DashboardHeader";
import RaceScheduleWidget from "../../widgets/RaceScheduleWidget";


const ResetPassword = () => {
  const {register, handleSubmit, formState: {errors}, watch} = useForm({mode: 'onBlur'});
  const {user} = useContext(LoginContext)
  const history = useHistory()

  const resetPasswordSubmit = async (submitData) => {
    const loadingToast = toast.loading('Please wait...')
    try {
      
      const formattedBody = formatResetPasswordBody(submitData, user)
          
      const resetPasswordResponse = await submitForm(formattedBody,"/user/change-password-logged-in-user")
      
      toast.dismiss(loadingToast)
      if (resetPasswordResponse.status === 'success' && resetPasswordResponse.status !== 'error') {
        const sendNotificationBody = {
          Parameters: [
            {
              Value: formattedBody.UserName,
              Key: "userDisplayName"
            }],
          EmailTemplate: "InsightsPasswordResetSuccess",
          Recipient: formattedBody.UserName
        };
        await submitForm(sendNotificationBody, process.env.REACT_APP_SEND_NOTIFICATION);
        history.push('/schedule')
      } else {
        toast.error('There has been a problem resetting your password. \n It cannot repeat last 4 passwords. \n It cannot contain first and last name of the user.')
      }

    } catch (error) {
      toast.error('There has been a problem resetting your password.')
    }
  }

  const checkPasswordsEqual = (val) => {
    if (watch('newPassword') !== val) return 'Passwords must match'
  }

  return (
    <div className="raceScheduleContainer">
    
    <div className="headerContainer">
      <DashboardHeader
        leftContent={<RaceScheduleWidget isInRaceSchedule={true} />}
      />
    </div>
    <div className="settingsFormContainer">
          <h1 className="insightsForm__title">Reset Password</h1>
        
      <form className='insightsForm' onSubmit={handleSubmit(resetPasswordSubmit)}>
        <label className='insightsForm__label' htmlFor='newPassword'>New Password</label>
        <input className={`insightsForm__input ${errors.newPassword ? 'insightsForm__input--error' : ''} `}
               type='password' {...register('newPassword', {
          required: true,
          pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/g
        })}/>
        {errors.newPassword && errors.newPassword.type === 'required' &&
          <p className='insightsForm__error'>This field is required</p>}
        {errors.newPassword && errors.newPassword.type === 'pattern' &&
          <p className='insightsForm__error'>The password must be at least 8 characters, contain an uppercase letter,
            lowercase letter and a number</p>}
        <label className='insightsForm__label' htmlFor='repeatPassword'>Repeat New Password</label>
        <input className={`insightsForm__input ${errors.newPassword ? 'insightsForm__input--error' : ''} `}
               type='password' {...register('repeatPassword', {required: true, validate: checkPasswordsEqual})}/>
        {errors.repeatPassword && errors.repeatPassword.type === 'required' &&
          <p className='insightsForm__error'>This field is required</p>}
        {errors.repeatPassword && errors.repeatPassword.type === 'validate' &&
          <p className='insightsForm__error'>{errors.repeatPassword.message}</p>}
        <button
          className={`insightsForm__resetPasswordLogin ${
            Object.keys(errors).length > 0
              ? "insightsForm__resetPasswordLogin--disabled"
              : ""
          }`}
          onClick={handleSubmit(resetPasswordSubmit)}
          type="submit"
          disabled={Object.keys(errors).length > 0}
        >
          <p className="insightsForm__email">Continue</p>
          <img
            className="insightsForm__icon"
            src={
              Object.keys(errors).length > 0
                ? "/Sign_in_circle_disabled.svg"
                : "/Sign_in_circle_enabled.svg"
            }
            alt=""
          />
        </button>
      </form>
      </div>
    </div>
  );
};

export default ResetPassword;