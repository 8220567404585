import React, {useContext, useEffect, useState} from 'react';
import './VideoWidget.scss';
import {StreamDetailsContext} from '../../../context/streamDetails.context';
import {CurrentRaceEventContext, RaceTypeEnum} from "../../../context/currentRaceEvent.context";
import {VideoSelector} from './VideoSelector';
import VideoReplayComponent from "./VideoReplayComponent";
import VideoLiveComponent from "./VideoLiveComponent";
import {VideoReplaySyncContext} from "../../../context/videoReplaySync.context";
import ReactGA from "react-ga";
import {ReactGaEnum} from "../../../models/enums/reactGAEnum";
import {TimestampContext} from "../../../context/timestamp.context";

export enum VideoWidgetDefaultStreamType {
    WorldFeed = 'WorldFeed',
    Umpapp = 'Umpapp'
}

const defaultStreamTypeMatchers: { [type in VideoWidgetDefaultStreamType]: (streamItem: string) => boolean } = {
    [VideoWidgetDefaultStreamType.WorldFeed]: streamItem => streamItem.includes('__world_feed__') || streamItem.includes('World Feed') || streamItem.includes('sailgpworldfeed'),
    [VideoWidgetDefaultStreamType.Umpapp]: streamItem => streamItem.includes('__umpapp__') || streamItem.includes('Umpapp') || streamItem.includes('Ump App')  || streamItem.includes('sailgpumpapp')  || streamItem.includes('USA')
};

interface VideoWidgetProps {
    useTimestamps?: boolean;
    isPip?: boolean;
    defaultStreamType: VideoWidgetDefaultStreamType;
    isRaceSchedule?: boolean
    video?: number;
}

const VideoWidget = ({useTimestamps, defaultStreamType, isPip, isRaceSchedule, video}: VideoWidgetProps) => {
    const streamDetails = useContext(StreamDetailsContext);
    const {
        muted,
        playing,
        played,
        handlePlayPause,
        handleSeekMouseDown,
        handleSeekChange,
        handleSeekMouseUp,
        handleProgress,
        seekingTime,
        playedSeconds,
        handleMuteUnmute,
        volume,
        handleVolumeMouseDown,
        handleVolumeChange,
        handleVolumeMouseUp,
        setMuted
    } = useContext(VideoReplaySyncContext);

    const {raceType, broadcasterRegionalConfig} = useContext(CurrentRaceEventContext);
    const [streamId, setStreamId] = useState<string>();
    const [streamsAvailable, setStreamsAvailable] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [isReplay, setIsReplay] = useState<boolean>(true)
    const [, setTimestamp] = useContext(TimestampContext);
    
    // The first time the streams become available, try to set a sensible default value
    useEffect(() => {
        if (!streamDetails?.replayStreams && !streamDetails?.streamIds) return;
        setStreamsAvailable(true);
        let streamIdLocal;
        if (localStorage.getItem(`video${video}`)) {
             streamIdLocal = localStorage.getItem(`video${video}`);
        }

        if (streamDetails.streamIds && raceType === RaceTypeEnum.live) {

            const matchedStreamId = streamDetails?.streamIds.find(
                id => defaultStreamTypeMatchers[defaultStreamType](id)
            );

            if (streamIdLocal) {
                setStreamId(streamIdLocal);
                setIsReplay(false)
                return;
            }

            if (matchedStreamId) {
                setStreamId(matchedStreamId);
                setIsReplay(false)
                return;
            }
            setStreamId(streamDetails.streamIds[0]);
            setIsReplay(false)
        }

        if (streamDetails.replayStreams && raceType === RaceTypeEnum.replay) {
            const matchedStream = streamDetails.replayStreams.streams.find(
                stream => defaultStreamTypeMatchers[defaultStreamType](stream.name)
            );

            if (streamIdLocal) {
                setStreamId(streamIdLocal);
                setIsReplay(true)
                return;
            }

            if (matchedStream) {
                setStreamId(matchedStream.url);
                setIsReplay(true)
                return;
            }
            setStreamId(streamDetails.replayStreams.streams[0].url);
            setIsReplay(true)
        }
        
    }, [defaultStreamType, raceType, streamDetails?.replayStreams, streamDetails?.streamIds]);

    useEffect(() => {
        if (isRaceSchedule) {
            setMuted(true)
            setShowControls(false)
        } else {
            setMuted(false)
        }

    }, [])

    const handleStreamSelected = (streamId: string | undefined) => {
        streamId && localStorage.setItem(`video${video}`, streamId);
        ReactGA.event({
            category: raceType === RaceTypeEnum.live ? ReactGaEnum.LiveVideoStream : ReactGaEnum.ReplayVideoStream,
            action: `Stream selected for race type ${raceType} and stream id ${streamId}`
        });
        setStreamId(streamId)
    }

    return (
        <div className={`VideoWidgetContainer ${isRaceSchedule ? 'VideoWidgetContainer--raceSchedule' : ''} `}>
            {streamsAvailable && !isRaceSchedule && (
                <div className="controlsContainer">
                    <VideoSelector
                        value={streamId}
                        onChange={(id) => handleStreamSelected(id)}
                        disabled={!streamDetails}
                        raceType={raceType}
                        broadcasterRegionalConfig={broadcasterRegionalConfig}
                    />
                </div>
            )}

            <div
                className={`videoContainerRelative ${raceType === RaceTypeEnum.live ? 'videoContainerRelative--live' : ''}`}
                onMouseEnter={() => setShowControls(true)}
                onMouseLeave={() => setShowControls(false)}
            >
                {raceType === RaceTypeEnum.live
                    ? <VideoLiveComponent
                        setTimestamp={setTimestamp}
                        useTimestamps={useTimestamps}
                        streamDetails={streamDetails}
                        streamId={streamId}
                        isRaceSchedule={isRaceSchedule}
                        playing={playing}
                        handlePlayPause={handlePlayPause}
                        handleProgress={handleProgress}
                        volume={volume}
                        handleVolumeMouseDown={handleVolumeMouseDown}
                        handleVolumeChange={handleVolumeChange}
                        handleVolumeMouseUp={handleVolumeMouseUp}
                        showControls={showControls}
                        muted={muted}
                        handleMuteUnmute={handleMuteUnmute}
                    />
                    : <VideoReplayComponent
                        useTimestamps={useTimestamps || isPip}
                        streamDetails={streamDetails}
                        streamId={streamId}
                        muted={muted}
                        playing={playing}
                        played={played}
                        handlePlayPause={handlePlayPause}
                        handleSeekMouseDown={handleSeekMouseDown}
                        handleSeekChange={handleSeekChange}
                        handleSeekMouseUp={handleSeekMouseUp}
                        handleProgress={handleProgress}
                        seekingTime={seekingTime}
                        playedSeconds={playedSeconds}
                        handleMuteUnmute={handleMuteUnmute}
                        volume={volume}
                        handleVolumeMouseDown={handleVolumeMouseDown}
                        handleVolumeChange={handleVolumeChange}
                        handleVolumeMouseUp={handleVolumeMouseUp}
                        showControls={showControls}
                        isPip={isPip}
                        isRaceSchedule={isRaceSchedule}
                        isReplay={isReplay}
                    />
                }
            </div>
        </div>
    );
};

export default VideoWidget;
