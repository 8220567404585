import React, {useContext} from 'react';
import Emoji from 'react-emoji-render';
import './LiveInformationWidget.scss';
import {SlackNotificationContext} from '../../context/slackNotifications.context';

import {ReactComponent as SlackIcon} from "../../assets/slack-new-logo.svg";

import {DateTime} from 'luxon';
import {SlackSocketMessage} from '../../models/slackSocketModel';

const LiveInformationWidget = () => {
    const slackNotifications = useContext(SlackNotificationContext);

    function renderEventLocalTime(message: SlackSocketMessage): string {
        const timeStamp = Math.trunc(+message.ts);
        return DateTime
            .fromSeconds(timeStamp)
            .toFormat('HH:mm:ss');
    }

    return (
        <div className="keyInformationWidgetContainer">
            <div className="header">
                <span className="headerText">
                    <span className="live">LIVE INFORMATION</span>
                </span>
                <SlackIcon/>
            </div>
            <div className="slackMessageContainer">
                {slackNotifications.map((message, i) => {
                        return (
                            message.text && <li className="slackNotificationDisplay" key={i}>
                        <span className="message">
                          <Emoji text={message.text}/>

                        </span>
                                <span className="timeAndUser">
                            {renderEventLocalTime(message)}
                                    {' '}
                                    {message.userName}
                                    {!!message.jobTitle && ` (${message.jobTitle})`}
                        </span>
                            </li>
                        )
                    }
                )}
            </div>
        </div>
    );
};


export default LiveInformationWidget;
