import React, {useContext, useEffect, useState} from 'react';
import {Button, Popover} from "@mui/material";
import {LoginContext} from "../../context/login.context";
import './AdditionalUserDataModal.scss';
import '../../pages/Login/Forms.scss';
import {Controller, useForm} from "react-hook-form";
import {useContentProxyData} from "../../hooks/useContentProxyData";
import {ContentProxyTeam} from "../../models/contentProxyModel";
import {submitForm} from "../../utils/helpers/submitForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdditionalUserDataModal = () => {
    const {register, handleSubmit, formState: {errors}, control} = useForm(({mode: 'onBlur'}));
    const {user, setLoginStatus, setAdditionalDataFailed, additionalDataFailed, isVip} = useContext(LoginContext);
    const [openAdditionalDataPopover, setOpenAdditionalDataPopover] = useState<boolean>(true);
    const {data: teams} = useContentProxyData<ContentProxyTeam[]>('teams', 0, 'incGeneral=true&onlyActive=true');

    const needsUserInfo = () => {
        if (isVip) return false;

        const hasMissingData = !user?.favouriteTeam || !user?.gender;
        const hasDummyName =
            !user?.firstName ||
            user?.firstName === '.....' ||
            !user?.lastName ||
            user?.lastName === '.....';
        const hasDummyDob = !user?.dateOfBirth || user?.dateOfBirth === '1900-01-01';

        return hasMissingData || hasDummyName || hasDummyDob;
    }

    useEffect(() => {
        if (!needsUserInfo()) {
            setOpenAdditionalDataPopover(false);
        } else if (!additionalDataFailed) {
            setOpenAdditionalDataPopover(true);
        }
    }, [user, additionalDataFailed, isVip]);

    const onSubmit = async (data: any) => {
        setOpenAdditionalDataPopover(false);

        let formattedData: any = {};

        if ((!user?.firstName || user?.firstName === '.....') && data.firstName) {
            formattedData.firstName = data.firstName;
        }

        if ((!user?.lastName || user?.lastName === '.....') && data.lastName) {
            formattedData.lastName = data.lastName;
        }

        if (!user?.gender && data.gender) {
            formattedData.gender = data.gender;
        }

        if (!user?.favouriteTeam && data.favouriteTeam) {
            formattedData.favouriteTeam = data.favouriteTeam;
        }

        if ((!user?.dateOfBirth || user?.dateOfBirth === '1900-01-01') && data.dateOfBirth) {
            const date = new Date(data.dateOfBirth);
            formattedData.dateOfBirth = date.toISOString().split('T')[0];
        }

        if (process.env.REACT_APP_UPDATE_PROFILE) {
            const additionalDataResponse = await submitForm(formattedData, process.env.REACT_APP_UPDATE_PROFILE)

            if (additionalDataResponse.status === 'success') {
                const updatedUser = {...(user as any), ...formattedData};
                setLoginStatus(true, updatedUser);
            } else {
                setAdditionalDataFailed(true)
            }
        }
    }

    const genderOptions = {
        Male: 'MALE',
        Female: 'FEMALE',
        'Non-Binary': 'NONBINARY',
        'Prefer not to identify': 'PREFERNOT',
        Other: 'OTHER'
    }

    const getDisplayValue = (apiField: string) => {
        if (!user) return '';
        const value = user[apiField];
        if ((apiField === 'firstName' || apiField === 'lastName') && value === '.....') {
            return '';
        }
        if (apiField === 'dateOfBirth' && value === '1900-01-01') {
            return '';
        }
        return value;
    };

    return (
        <Popover open={openAdditionalDataPopover} anchorEl={document.getElementById('sliderContainer')}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
                 anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
                 style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
            <div className='additionalUserDataModal'>
                <div className='modalImage'/>
                <div className='modalTitleContainer'>
                    <h1 className='modalTitle'>
                        Before you continue
                    </h1>
                    <h1 className='modalTitle modalTitle__bold'> we need more information </h1>
                </div>

                <form className='insightsForm modalForm' onSubmit={handleSubmit(onSubmit)}>
                    {(!user?.firstName || user?.firstName === '.....') && (
                        <div className='modalForm__inputContainer'>
                            <label className='insightsForm__label modalForm__label' htmlFor='firstName'>
                                First Name
                            </label>
                            <input
                                className={`insightsForm__input modalForm__input ${errors.firstName ? "modalForm__input--error" : ""} `}
                                type="text"
                                {...register("firstName", {
                                    required: true,
                                    pattern: /^[a-z ,.'-]+$/i,
                                })}
                                defaultValue={getDisplayValue("firstName")}
                            />
                            {errors.firstName && errors.firstName.type === "required" && (
                                <p className="insightsForm__error">This field is required.</p>
                            )}
                            {errors.firstName && errors.firstName.type === "pattern" && (
                                <p className="insightsForm__error">
                                    Please enter a valid first name.
                                </p>
                            )}
                        </div>
                    )}

                    {(!user?.lastName || user?.lastName === '.....') && (
                        <div className='modalForm__inputContainer'>
                            <label className='insightsForm__label modalForm__label' htmlFor='lastName'>
                                Last Name
                            </label>
                            <input
                                className={`insightsForm__input modalForm__input ${errors.lastName ? "modalForm__input--error" : ""} `}
                                type="text"
                                {...register("lastName", {
                                    required: true,
                                    pattern: /^[a-z ,.'-]+$/i,
                                })}
                                defaultValue={getDisplayValue("lastName")}
                            />
                            {errors.lastName && errors.lastName.type === "required" && (
                                <p className="insightsForm__error">This field is required.</p>
                            )}
                            {errors.lastName && errors.lastName.type === "pattern" && (
                                <p className="insightsForm__error">
                                    Please enter a valid last name.
                                </p>
                            )}
                        </div>
                    )}

                    {(!user?.dateOfBirth || user?.dateOfBirth === '1900-01-01') && (
                        <div className='modalForm__inputContainer'>
                            <label className='insightsForm__label modalForm__label' htmlFor='dateOfBirth'>
                                Date of Birth
                            </label>
                            {/* @ts-ignore */}
                            <Controller
                                control={control}
                                name="dateOfBirth"
                                rules={{ required: true }}
                                defaultValue={null}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            name="dateOfBirth"
                                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                                            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 120))}
                                            selected={field.value}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            placeholderText="DD/MM/YYYY"
                                            className={`insightsForm__input modalForm__input ${errors.dateOfBirth ? "modalForm__input--error" : ""}`}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    );
                                }}
                            />
                            {errors.dateOfBirth && errors.dateOfBirth.type === "required" && (
                                <p className="insightsForm__error">This field is required.</p>
                            )}
                        </div>
                    )}

                    {!user?.gender && (
                        <div className='modalForm__inputContainer'>
                            <label className='insightsForm__label modalForm__label' htmlFor='gender'>
                                Gender
                            </label>
                            <select
                                className={`insightsForm__input modalForm__input ${errors.gender ? "modalForm__input--error" : ""} `}
                                {...register('gender', {required: true})}>
                                <option
                                    value=""
                                    className="select-option"
                                >
                                    -- Please select --
                                </option>
                                {Object.keys(genderOptions).map(genderOption => {
                                    return <option key={genderOption}
                                                   value={genderOptions[genderOption]}>{genderOption}</option>
                                })}
                            </select>
                            {errors.gender && errors.gender.type === "required" && (
                                <p className="insightsForm__error">This field is required.</p>
                            )}
                        </div>
                    )}


                    {!user?.favouriteTeam && (
                        <div className='modalForm__inputContainer'>
                            <label className='insightsForm__label modalForm__label' htmlFor='favouriteTeam'>
                                Favourite Team
                            </label>
                            <select
                                className={`insightsForm__input modalForm__input ${errors.favouriteTeam ? "modalForm__input--error" : ""} `}
                                {...register('favouriteTeam', {required: true})}>
                                <option
                                    value=""
                                    className="select-option"
                                >
                                    -- Please select --
                                </option>
                                {teams && teams.map(team => {
                                    return <option key={team.contentfulId} value={team.code}>{team.name}</option>
                                })}
                            </select>
                            {errors.favouriteTeam && errors.favouriteTeam.type === "required" && (
                                <p className="insightsForm__error">This field is required.</p>
                            )}
                        </div>
                    )}

                    <Button className={`modalForm__submit ${
                        Object.keys(errors).length > 0
                            ? "modalForm__submit--disabled"
                            : ""
                    }`}
                            type='submit'
                            disabled={Object.keys(errors).length > 0}>
                        Continue
                    </Button>
                </form>
            </div>
        </Popover>
    );
};

export default AdditionalUserDataModal;